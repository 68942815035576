<template>
  <div class="flex flex-wrap items-center justify-between mb-4">
    <div class="mr-8 mb-4">
      <h1 class="text-xl font-bold">{{ heading }}</h1>
      <rich-text-field
        v-if="infoHtml"
        v-model="infoHtml"
        :hideBorder="true"
        :showEditor="false"
      />
    </div>

    <div class="mb-4">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import RichTextField from '../form/RichTextField';
export default {
  props: {
    heading: {
      type: String,
      required: true
    },
    infoHtml: {
      type: String
    }
  },

  components: {
    RichTextField
  }
};
</script>
