<template>
  <div>
    <ui-header :heading="$tc('models.user', 2)">
      <ui-link class="add_new_button" type="text" :route="{ name: 'users-create' }">{{
        `${$tc('actions.add_new', 1)} ${$tc('models.user')}`
      }}</ui-link>
    </ui-header>

    <user-list
      @onFilter="filterUsers"
      @clearFilter="onClear"
      :users="users"
      v-loading="loading"
    >
    </user-list>
  </div>
</template>

<script>
import UiHeader from '@/components/ui/UiHeader';
import UiLink from '@/components/ui/UiLink';
import UserList from '../components/UserList';
import { getUsers, filterUsersRequest } from '../api';

export default {
  data() {
    return {
      users: [],
      loading: false
    };
  },

  components: {
    UiHeader,
    UiLink,
    UserList
  },

  methods: {
    async getUsers() {
      this.loading = true;
      try {
        const users = await getUsers();
        this.users = users;
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },

    async filterUsers(filterDetails) {
      var users = await filterUsersRequest(
        filterDetails.companyId,
        filterDetails.buildingId,
        filterDetails.departmentId
      );
      if (users !== '') {
        this.users = users;
      } else {
        this.users = [];
      }
    },

    onClear() {
      this.getUsers();
    }
  },

  created() {
    this.getUsers();
  },

  mounted() {
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.users", link: "/app/users/"},
    ]);
  },
};
</script>

<style scoped>
.add_new_button {
  background-color: white;
  border: solid 1px black;
  color: black;
}
</style>
