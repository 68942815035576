<template>
  <div class="progress_bar_wrapper">
    <div class="progress_bar">
      <div class="progress_bar_done" :style="getProgressBarDoneStyle(percentage)">
        <span v-if="percentage > 14" class="progress_bar_inner_text">{{ percentage }}%</span>
      </div>
      <div class="progress_bar_done">
        <span v-if="percentage <= 14" class="unfinished_progress_bar_inner_text">{{ percentage }}%</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "UiProgressBar",
  props: {
    percentage: {
      required: true
    }
  },

  mounted() {

  },

  methods: {
    getProgressBarDoneStyle(percentage) {
      return "backgroundColor: hsl(" + percentage + ", 100%, 42.4%;" + "width: " + percentage + "%";
    },

    getProgressBarNotDoneWidth(percentage) {
      let percentageRemaining = 100 - percentage
      return percentageRemaining + "%"
    }
  }
}


</script>

<style scoped>

.progress_bar_wrapper {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.progress_bar_percentage {
  display: inline-flex;
  height: 1em;
  align-items: center;
  width: 2em;
  margin-right: 1em;
  border-radius: 1.8em;
}

.progress_bar {
  display: inline-flex;
  width: 100%;
  background-color: #ececec;
  border-radius: 1.8em;
}

.progress_bar_done {
  /*display: inline;*/
  height: 1em;
  border-radius: 1.8em;
  align-items: center;
  display: flex;
  text-align: end;
}

.progress_bar_inner_text {
  width: 100%;
  height: 16px;
  margin-top: -8px;
  align-self: center;
  font-size: 0.90rem;
  color: white;
  font-weight: bold;
  margin-left: -4px;
}

.unfinished_progress_bar_inner_text {
  width: 100%;
  height: 16px;
  margin-top: -7px;
  align-self: center;
  font-size: 0.90rem;
  color: black;
  padding-left: 2px;
}

</style>