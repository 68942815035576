<template>
  <svg :height="h" :width="w">
    <polygon :class="`icon ${active ? 'active' : ''}`" :points="getPoints()" />
    Sorry, your browser does not support inline SVG.
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'small'
    }
  },

  data() {
    return {
      h: 0,
      w: 0
    };
  },

  methods: {
    getHeight(multiplyer) {
      return 10 * multiplyer;
    },

    getWidth(multiplyer) {
      return 20 * multiplyer;
    },

    getPoints() {
      const p0w = 1;
      const p0h = 1;
      const p1w = this.w / 2;
      const p1h = this.h - 1;
      const p2w = this.w - 1;
      const p2h = 1;
      const points = `${p0w},${p0h} ${p1w},${p1h} ${p2w},${p2h}`;
      return points;
    }
  },

  created() {
    let multiplyer = 1;
    if (this.size === 'small') multiplyer = 0.8;
    this.h = this.getHeight(multiplyer);
    this.w = this.getWidth(multiplyer);
  }
};
</script>

<style scoped>
.icon {
  fill: #718096;
  /* stroke: black; */
  /* stroke-width: 0; */
}

.active {
  fill: #4299e1;
}
</style>
