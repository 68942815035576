import api, { http, fileDownload } from '@/services/api';

export const getCompanies = async () => {
  const result = await api.get('/companies');
  return result.data;
};

export const getCompany = async id => {
  const result = await api.get(`/companies/${id}`);
  return result.data;
};

export const createCompany = async payload => {
  const result = await api.post('/companies', payload);
  return result.data;
};

export const updateCompany = async payload => {
  const result = await api.patch(`/companies/${payload.id}`, payload);
  return result.data;
};

export const deleteCompany = async id => {
  const result = await api.delete(`/companies/${id}`);
  return result.data;
};

export const getCompanyBuildings = async id => {
  const result = await api.get(`/companies/${id}/buildings`);
  return result.data;
};

export const getCompanyDepartments = async id => {
  const result = await api.get(`/companies/${id}/departments`);
  return result.data;
};

export const getCompanyUsers = async id => {
  const result = await api.get(`/companies/${id}/users`);
  return result.data;
};

export const removeCompanyUser = async (companyId, userId) => {
  const result = await api.delete(`/companies/${companyId}/users/${userId}`);
  return result.data;
};

export const getCompanyRole = async () => {
  const result = await api.get(`/companies/role`);
  return result.data;
};

export const uploadCompanyLogo = async (payload, companyId) => {
  const fd = new FormData();
  fd.append('image', payload);
  const result = await http.request({
    method: 'post',
    url: '/images/logo/' + companyId,
    data: fd,
    headers: { 'content-type': 'multipart/form-data' }
  });
  return result.data;
}

export const uploadCompanyBackground = async (payload, companyId) => {
  const fd = new FormData();
  fd.append('image', payload);
  const result = await http.request({
    method: 'post',
    url: '/images/background/company/' + companyId,
    data: fd,
    headers: { 'content-type': 'multipart/form-data' }
  });
  return result.data;
}

export const getLogoPath = async () => {
  const result = await http.request({
    method: "get",
    url: "images/logo",
  });
  return result.data;
}

export const deleteLogo = async (companyId) => {
  await http.request({
    method: "delete",
    url: "/images/logo/" + companyId
  });
}

export const deleteBackground = async (companyId) => {
  await http.request({
    method: "delete",
    url: "/images/background/company/" + companyId
  })
}

export const backgroundExists = async (companyId) => {
  const result = await http.request({
    method: "get",
    url: "/images/background/company/exists/" + companyId
  });
  return result.data;
}

export const logoExists = async (companyId) => {
  const result = await http.request({
    method: "get",
    url: "/images/logo/company/exists/" + companyId
  });
  return result.data;
}

export const getCompanyPermissions = async (companyId) => {
  const result = await http.request({
    method: "get",
    url: `/companies/${companyId}/permissions`
  });

  return result.data;
}

export const getActiveCompanyPermissions = async (companyId) => {
  const result = await http.request({
    method: "get",
    url: `/companies/${companyId}/permissions?active=true`
  });

  return result.data;
}

export const putCompanyPermissions = async (companyId, permissionIds) => {
  const response = await http.request({
    method: "put",
    url: `/companies/${companyId}/permissions`,
    data: permissionIds
  });

  return response.data;
}

export const downloadStudentDataExcel = async (companyId, startDate, endDate) => {
  await fileDownload(`/api/students/exportExcel?companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`, "student_excel_report.xlsx");
}

export const downloadUserDataExcel = async (companyId, startDate, endDate) => {
  await fileDownload(`/api/users/exportExcel?companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`, "user_excel_report.xlsx");
}

/* Registration links */
export const createRegistrationLink = async payload => {
  const result = await api.post(`/registrationlinks/`, payload);
  return result.data;
}

export const updateRegistrationLink = async payload => {
  const result = await api.put(`/registrationlinks/${payload.reference}`, payload);
  return result.data;
}

export const getRegistrationLinkByReference = async reference => {
  const result = await api.get(`/registrationlinks/${reference}`);
  return result.data;
}


export const getRegistrationLinkByCompanyId = async companyId => {
  const result = await api.get(`/registrationlinks?companyId=${companyId}`);
  return result.data;
}

export const getRegistrationLinkByReferenceExtended = async reference => {
  const result = await api.get(`/registrationlinks/${reference}/extended`);
  return result.data;
}

export const associateUserToReference = async (reference, email, departmentId, password) => {
  const result = await http.post(`/registrationlinks/${reference}/associateuser`, {}, { params: { email, departmentId, password } });
  return result.data;
}

export const deleteRegistrationLinkByReference = async reference => {
  const result = await api.delete(`/registrationlinks/${reference}`);
  return result.data;
}

export const getRegistrationLinks = async () => {
  const result = await api.get(`/registrationlinks`);
  return result.data;
}
