<template>
  <div>
    <!-- The table itself -->
    <ui-list
      :heading="heading"
      :headings="[$t('fields.name'), $tc('models.role'), $tc('misc.not-logged-in'), 'search']"
      :items="users"
      :sort="[
        { prop: 'firstName', type: false },
        //{ prop: 'role', prop2: 'name', type: 'level2' }
      ]"
      :search_selectors="['firstName', 'lastName', 'email', 'role']"
      @onFilter="onFilter"
    >
      <!-- pass filter section to slot -->
      <template v-slot:filter>
        <!-- header part containing filtering options -->
        <el-row class="bg-gray-100 border-b border-gray-300">
          <form class="px-8 pt-6">
            <div class="flex -mx-3">
              <!-- Filter by company -->
              <div class="flex-auto px-3 mb-5 md:mb-0">
                <ui-form-field :label="$tc('labels.user.company_admin')">
                  <company-field
                    class="w-full"
                    v-model="company"
                  ></company-field>
                </ui-form-field>
              </div>
              <!-- Filter by building -->
              <div class="flex-auto px-3 mb-5 md:mb-0">
                <ui-form-field :label="$tc('labels.user.building_admin')">
                  <building-field
                    class="w-full"
                    v-model="building"
                  ></building-field>
                </ui-form-field>
              </div>
              <!-- Filter by department -->
              <div class="flex-auto px-3 mb-5 md:mb-0">
                <ui-form-field :label="$tc('models.user', 2)">
                  <department-field
                    class="w-full"
                    v-model="department"
                  ></department-field>
                </ui-form-field>
              </div>
              <!-- Filter button -->
              <div class="flex-initial pl-3 mb-5 md:mb-0 custom-margin ">
                <ui-button type="grey" @click="onFilter">
                  {{ $tc('labels.filter') }}
                </ui-button>
              </div>
              <!-- Clear button -->
              <div class="flex-initial px-3 mb-5 md:mb-0 custom-margin">
                <ui-button type="grey" @click="clearFilter">
                  Clear
                </ui-button>
              </div>
            </div>
          </form>
        </el-row>
      </template>

      <template v-slot:default="props">
        <ui-list-data
          :text="
            `${
              props.item.firstName
                ? props.item.firstName
                : $t('misc.not-registered')
            } ${props.item.lastName ? props.item.lastName : ''}`
          "
          :info="props.item.email"
        >
        </ui-list-data>

        <ui-list-data type="tag" :text="$t(`roles.${props.item.role.name}`)" />
          <ui-list-data type="tag" v-if="props.item.firstName == null && props.item.lastName == null" :text="$t('misc.not-logged-in')"/>
        <ui-list-data type="tag" v-else />
        <ui-list-data
          type="tag"
          class="flex justify-end"
          style="padding: 31px 24px"
        >
          <ui-button
            type="grey"
            uppercase
            @click="
              $router.push({
                name: 'users-edit',
                params: { id: props.item.id }
              })
            "
            >{{ $t('actions.edit') }}</ui-button
          >
        </ui-list-data>
      </template>
    </ui-list>
  </div>
</template>

<script>
import UiList from '@/components/ui/UiList';
import UiListData from '@/components/ui/UiListData';
import UiFormField from '@/components/ui/UiFormField';
import CompanyField from '@/components/form/CompanyField';
import BuildingField from '@/components/form/BuildingField';
import DepartmentField from '@/components/form/DepartmentField';
import UiButton from '@/components/ui/UiButton';

export default {
  props: {
    heading: {
      type: String
    },
    users: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      company: null,
      building: null,
      department: null,
      loading: false
    };
  },

  components: {
    UiButton,
    UiList,
    UiListData,
    UiFormField,
    CompanyField,
    BuildingField,
    DepartmentField
  },

  methods: {
    onFilter: function() {
      if (
        this.company !== null ||
        this.building !== null ||
        this.department !== null
      ) {
        this.$emit('onFilter', {
          companyId: this.company !== null ? this.company.id : null,
          buildingId: this.building !== null ? this.building.id : null,
          departmentId: this.department !== null ? this.department.id : null
        });
      }
    },

    clearFilter: function() {
      this.company = null;
      this.building = null;
      this.department = null;
      this.$emit('clearFilter');
    }
  }
};
</script>

<style scoped>
.custom-margin {
  margin-top: 28px;
}
</style>
