<template>
  <td :class="{ small: small }">
    <span v-if="text" :class="styles">
      <a
        v-if="$listeners.click"
        class="underline hover:text-theme-primary-hover"
        href="#"
        @click.prevent="handleClick"
        >{{ text }}</a
      >
      <span v-else>{{ text }}</span>

      <span v-if="progressPrecentage !== null">
        <ui-progress-bar :percentage="courseProgress"/>
      </span>
    </span>

    <span v-if="info" class="mt-1 block text-sm text-gray-700">{{ info }}</span>
    <span v-if="infoHtml" class="mt-1 block text-sm text-gray-700"
      ><rich-text-field
        v-model="infoHtml"
        :hideBorder="true"
        :showEditor="false"
    /></span>

    <div v-if="$slots.default" class="flex">
      <slot></slot>
    </div>
  </td>
</template>

<script>
import RichTextField from '../form/RichTextField';
import UiProgressBar from "@/components/ui/UiProgressBar";
export default {
  props: {
    type: {
      type: String,
      default: 'default'
    },
    text: {
      type: String
    },
    info: {
      type: String
    },
    infoHtml: {
      type: String
    },
    progressPrecentage: {
      type: Number,
      default: null
    },
    small: {
      type: Boolean,
      default: false
    }
  },

  components: {
    UiProgressBar,
    RichTextField
  },

  computed: {
    styles() {
      if (this.type === 'default') {
        return ['block'];
      }

      if (this.type === 'tag') {
        return [
          'whitespace-no-wrap',
          'text-xs',
          'font-bold',
          'rounded-full',
          'px-3',
          'py-1',
          'bg-blue-100',
          'text-gray-600'
        ];
      } else return [];
    },
    courseProgress() {
      if (this.progressPrecentage !== null) return this.progressPrecentage;
      else return null;
    }
  },

  methods: {
    handleClick() {
      this.$emit('click');
    }
  }
};
</script>
<style scoped>
</style>
