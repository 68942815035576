import api, {http} from '@/services/api';

export const getDepartments = async () => {
  const result = await api.get('/departments');
  return result.data;
};

export const getDepartment = async id => {
  const result = await api.get(`/departments/${id}`);
  return result.data;
};

export const createDepartment = async payload => {
  const result = await api.post('/departments', payload);
  return result.data;
};

export const updateDepartment = async payload => {
  const result = await api.patch(`/departments/${payload.id}`, payload);
  return result.data;
};

export const deleteDepartment = async id => {
  const result = await api.delete(`/departments/${id}`);
  return result.data;
};

export const getDepartmentUsers = async did => {
  const result = await api.get(`/departments/${did}/users`);
  return result.data;
};

export const deleteDepartmentUser = async (departmentId, userId) => {
  const result = await api.delete(
    `/departments/${departmentId}/users/${userId}`
  );
  return result.data;
};

export const getCourses = async departmentId => {
  const result = await api.get(`/departments/${departmentId}/courses`);
  return result.data;
};

export const getCourse = async (departmentId, courseId) => {
  const result = await api.get(
    `/departments/${departmentId}/courses/${courseId}`
  );
  return result.data;
};

export const getUserNotInCourse = async (departmentId, courseId) => {
  const result = await api.get(
    `/departments/${departmentId}/courses/${courseId}/notincourse`
  );
  return result.data;
};

export const assignCourse = async payload => {
  const result = await api.post(
    `/departments/${payload.departmentId}/courses/${payload.courseId}`,
    payload
  );
  return result.data;
};

export const deleteUserCourse = async payload => {
  const result = await api.delete(
    `/departments/${payload.departmentId}/courses/${payload.courseId}/users/${payload.userId}`
  );
  return result.data;
};

export const courseReminder = async payload => {
  const result = await api.get(
    `/departments/${payload.departmentId}/courses/${payload.courseId}/users/${payload.userId}/reminder`
  );
  return result.data;
};

// Active course calls
export const getActiveCourses = async departmentId => {
    const result = await api.get(`/activecourse/${departmentId}`);
    if (result.status == 204) return [];
    return result.data;
}

export const createActiveCourse = async (departmentId, courseId) => {
  const result = await http.request({
      method: 'post',
      url: '/activecourse',
      data: {
          departmentId,
          courseId
      },
    });

    return result.data;
}

export const deleteActiveCourse = async (departmentId, courseId) => {
  const result = await http.request({
      method: 'delete',
      url: '/activecourse',
      data: {
          departmentId: departmentId,
          courseId: courseId
      },
      options: { 
        'ContentType': 'application/json'
      }
    });

    return result.data;
}

export const getCoursesByBuildingId = async buildingId => {
  const result = await api.get(`/buildings/${buildingId}/course`);
  return result.data;
}
