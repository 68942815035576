import api, {http} from '@/services/api';

export const getUsers = async () => {
  const result = await api.get('/users');
  return result.data;
};

export const getUser = async (id) => {
  const result = await api.get(`/users/${id}`);
  return result.data;
};

export const createUser = async (payload) => {
  const result = await api.post('/users', payload);
  return result.data;
};

export const createUsers = async (payload) => {
  const result = await api.post('/users/import', payload)
  return result.data;
}

export const updateUser = async (payload) => {
  const result = await api.patch(`/users/${payload.id}`, payload);
  return result.data;
};

export const deleteUser = async (id) => {
  const result = await api.delete(`/users/${id}`);
  return result.data;
};

export const filterUsersRequest = async (
  companyId,
  buildingId,
  departmentId
) => {
  const result = await api.get(
    `/users/filter/${companyId}/${buildingId}/${departmentId}`
  );
  return result.data;
};

export const getUserDepartment = async () => {
  const result = await api.get(`/users/department`);
  return result.data;
};

export const getBackground = async () => {
  const result = await api.get(`/images/background`);
  return result.data;
};

export const getUserCourses = async (userId) => {
  const result = await api.get(`/myCourses/user/${userId}`);
  return result.data;
};

export const deleteUserCourse = async (userCourseId, userId) => {
  const result = await api.delete(`/myCourses/${userCourseId}/user/${userId}`);
  return result.data;
}

// Get department users
export const getDepartmentUsersByUser = async (userId) => {
  const result = await api.get(`/departments/user/${userId}`);
  return result.data;
}

// Get building users
export const getBuildingUsersByUserId = async (userId) => {
  const result = await api.get(`/buildings/user/${userId}`);
  return result.data;
}

// Get company users
export const getCompanyUsersByUserId = async (userId) => {
  const result = await api.get(`/companies/user/${userId}`);
  return result.data;
}

// Delete departmentUser by dpUser ID
export const deleteDepartmentUserById = async (departmentUserId) => {
  const result = await api.delete();
  return result.data;
}

export const getUserPermissions = async (userId) => {
  const response = await api.get(`/users/${userId}/permissions`)
  return response.data;
}

export const putUserPermissions = async (userId, permissionIds) => {
  const response = await http.request({
    method: "put",
    url: `/users/${userId}/permissions`,
    data: permissionIds
  });

  return response.data;
}
