<template>
  <div class="bg-white rounded overflow-hidden">
    <slot name="filter"></slot>
    <div
      v-if="heading || $slots.actions"
      class="p-4 md:p-6 flex justify-between items-center"
    >
      <div
        v-if="heading"
        class="font-bold uppercase"
      >
        {{ heading }}
      </div>
      <div v-if="$slots.actions">
        <slot name="actions"></slot>
      </div>
    </div>
    <div
      class="w-full"
      :class="{ 'overflow-x-scroll': !disableXScroll }"
    >
      <table class="table">
        <thead>
          <tr>
            <th
              v-for="(heading, index) in headings"
              :key="index"
              style="border-bottom-width: 0"
            >
              <!-- Check if the header says search, if so render a search box instead -->
              <template v-if="heading === 'search'">
                <el-input
                    v-model="search"
                    placeholder="Type to search"
                />
              </template>
              <!-- If the header has 'template', you can use a template in the table header -->
              <template v-else-if="heading === 'template'">
                <slot :name="'tmpl-' + index"></slot>
              </template>
              <!-- If the header says something else then search or nothing -->
              <template v-else>
                <div v-if="!sort || !sort[index]">
                  {{ heading }}
                </div>
                <div v-if="sort && sort[index]">
                  <div
                    class="sort-column"
                    v-if="currentSort.prop !== sort[index].prop"
                    @click="onSort(sort[index], true)"
                  >
                    {{ heading }}
                    <div class="icon-container">
                      <ui-triangle-up />
                      <ui-triangle-down />
                    </div>
                  </div>
                  <div
                    class="sort-column"
                    v-if="currentSort.prop === sort[index].prop && currentSort.asc === false"
                    @click="onSort(sort[index], true)"
                  >
                    {{ heading }}
                    <div class="icon-container">
                      <ui-triangle-up :active="true" />
                      <ui-triangle-down />
                    </div>
                  </div>
                  <div
                    class="sort-column"
                    v-if="currentSort.prop === sort[index].prop && currentSort.asc === true"
                    @click="onSort(sort[index], false)"
                  >
                    {{ heading }}
                    <div class="icon-container">
                      <ui-triangle-up />
                      <ui-triangle-down :active="true" />
                    </div>
                  </div>
                </div>
              </template>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(item, index) in computedPaginatedList"
            :key="item.id ? item.id : 'item_' + index"
          >
            <slot v-bind:item="item"></slot>
          </tr>
          <slot name="footer"></slot>
        </tbody>
      </table>
      <!-- Pagination -->
      <div class="block px-4 pt-2 pb-4 md:px-6 md:pt-2 md:pb-6">
        <el-pagination
          :hide-on-single-page="true"
          @size-change="handlePaginationSizeChange"
          @current-change="handleItemsPaginationChange"
          :current-page.sync="currentPage"
          :page-sizes="[10, 25, 50, 100]"
          :page-size="25"
          layout="total, sizes, prev, pager, next, jumper"
          :total="filteredItems !== null ? filteredItems.length : items.length"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import UiTriangleUp from "@/components/ui/UiTriangleUp";
import UiTriangleDown from "@/components/ui/UiTriangleDown";
export default {
  props: {
    heading: {
      type: String,
    },
    headings: {
      type: Array,
      required: true,
    },
    sort: {
      type: Array,
      required: false,
    },
    items: {
      type: Array,
      required: true,
    },
    search_selectors: {
      type: Array,
      required: false,
    },
    selectedFilterVal: {
      type: Object,
      required: false,
    },
    disableXScroll: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  components: {
    UiTriangleDown,
    UiTriangleUp,
  },

  data() {
    return {
      currentSort: null,
      search: "",
      timeout: null,
      filteredItems: null,
      showSearchResults: false,
      // Pagination
      currentPage: 1,
      minNumbItems: 0,
      maxNumbItems: 25,
      selectedPaginationSize: 25,
    };
  },

  computed: {
    computedPaginatedList() {
      if (this.filteredItems !== null) {
        return this.filteredItems.slice(this.minNumbItems, this.maxNumbItems);
      } else {
        return this.items.slice(this.minNumbItems, this.maxNumbItems);
      }
    },
  },

  watch: {
    items: function () {
      this.filteredItems = this.items;
    },

    // If the search value is changed then
    search: function (search) {
      clearTimeout(this.timeout);
      let self = this;

      // Debounce logic. 0,5s after user stops typing, this will search for items matching search
      this.timeout = setTimeout(function () {
        let filteredList = [];
        // Filter through list of items. If the users' name includes the search string the user is included
        self.items.forEach((item) => {
          if (search.includes("-")) {
            let foundValueCount = 0;
            self.search_selectors.forEach((selector) => {
              if (item[selector] !== null) {
                foundValueCount += 1;
              }
            });

            if (foundValueCount === 0) {
              filteredList.push(item);
            }
          } else if (search === "") {
            filteredList.push(item);
          } else {
            let completeSelectorBuilded = "";

            self.search_selectors.forEach(selector => {
              if (typeof selector === 'object') {
                if (selector.type === 'translation') {
                  if (item[selector.prop] !== undefined) {
                    completeSelectorBuilded += item[selector.prop].filter(p => p.lang === self.$i18n.locale)[0].value;
                  }
                }
                else if (selector.type === 'level2') {
                  if (item[selector.prop] !== undefined && item[selector.prop][selector.prop2] !== undefined) {
                    completeSelectorBuilded += item[selector.prop][selector.prop2];
                  }
                }
              }
              if (
                self.search_selectors.indexOf(selector) >=
                self.search_selectors.length
              ) {
                completeSelectorBuilded += item[selector];
              } else {
                completeSelectorBuilded += item[selector] + " ";
              }
            });

            if (completeSelectorBuilded.toLowerCase().includes(search.toLowerCase())) {
              filteredList.push(item);
            }
          }
        });
        self.filteredItems = filteredList;
      }, 500);
    },
  },

  methods: {
    sortTranslation(property, asc) {
      try {
        const mod = asc ? 1 : -1;

        if (this.filteredItems !== null) {
          this.filteredItems.sort((a, b) =>
            a[property].filter((p) => p.lang === this.$i18n.locale)[0].value >
            b[property].filter((p) => p.lang === this.$i18n.locale)[0].value
              ? mod
              : -1 * mod
          );
        } else {
          this.items.sort((a, b) =>
            a[property].filter((p) => p.lang === this.$i18n.locale)[0].value >
            b[property].filter((p) => p.lang === this.$i18n.locale)[0].value
              ? 1 * mod
              : -1 * mod
          );
        }

        this.currentSort = { prop: property, asc: asc };
      } catch (err) {}
    },

    sortLevel2(prop, prop2, asc) {
      try {
        const mod = asc ? 1 : -1;

        if (this.filteredItems !== null) {
          this.filteredItems.sort((a, b) => (a[prop][prop2] > b[prop][prop2] ? 1 * mod : -1 * mod));
        } else {
          this.items.sort((a, b) => (a[prop][prop2] > b[prop][prop2] ? 1 * mod : -1 * mod));
        }

        this.currentSort = { prop: prop, asc: asc };
      } catch (err) {}
    },

    sortSimple(property, asc) {
      try {
        const mod = asc ? 1 : -1;
        if (this.filteredItems !== null) {
          this.filteredItems.sort((a, b) => (a[property] > b[property] ? 1 * mod : -1 * mod));       
        } else {
          this.items.sort((a, b) => (a[property] > b[property] ? 1 * mod : -1 * mod));
        }
        this.currentSort = { prop: property, asc: asc };
      } catch (err) {}
    },

    onSort(field, asc) {
      switch (field.type) {
        case "translation":
          this.sortTranslation(field.prop, asc);
          break;
        case "level2":
          this.sortLevel2(field.prop, field.prop2, asc);
          break;
        default:
          this.sortSimple(field.prop, asc);
          break;
      }
    },

    handlePaginationSizeChange(value) {
      this.selectedPaginationSize = value;
      if (this.currentPage <= 1) {
        this.minNumbItems = 0;
        this.maxNumbItems = value;
      } else {
        this.minNumbItems = (this.currentPage - 1) * value;
        this.maxNumbItems = this.currentPage * value;
      }
    },

    handleItemsPaginationChange(value) {
      this.currentPage = value;
      if (value <= 1) {
        this.minNumbItems = 0;
        this.maxNumbItems = this.selectedPaginationSize;
      } else {
        this.minNumbItems = (value - 1) * this.selectedPaginationSize;
        this.maxNumbItems = value * this.selectedPaginationSize;
      }
    },
  },

  created() {
    if (this.sort && this.sort.length > 0) {
      this.currentSort = { prop: this.sort[0].prop, asc: true };
    }
  },
};
</script>

<style lang="scss" scoped>
table {
  @apply w-full;
  min-width: 40rem;
}

th,
td {
  @apply p-4;
  @screen md {
    @apply p-6;
  }
}

.filter-section {
  @apply bg-gray-100;
}

th {
  @apply bg-gray-100 border-b border-gray-300 text-left uppercase font-bold text-sm text-gray-600;
}

td {
  @apply border-t border-gray-300;
}

td.small {
  @apply whitespace-no-wrap w-px;
}

.icon-container {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-left: 0.5rem;
}

.icon {
  display: inline-block;
  width: 0.6rem;
  margin-left: 0.5rem;
  fill: #718096;
}

.active {
  fill: #4299e1;
}

.sort-column {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.searchInput {
  min-width: 200px;
}

</style>
