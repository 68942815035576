<template>
  <el-select
    v-model="localValue"
    :placeholder="`${$t('actions.select')} ${$tc('models.department')}`"
    :loading="loading"
    filterable
    value-key="id"
    @change="handleSelect"
  >
    <el-option
      v-for="department in departments"
      :key="department.id"
      :label="department.name"
      :value="department"
    >
    </el-option>
  </el-select>
</template>

<script>
import { getDepartments } from '@/app/departments/api';

export default {
  props: {
    value: {
      type: Object,
      default: () => []
    }
  },

  data() {
    return {
      localValue: this.value,
      departments: [],
      loading: false
    };
  },

  watch: {
    value(value) {
      this.localValue = value;
    }
  },

  methods: {
    handleSelect() {
      this.$emit('input', this.localValue);
    },

    async getDepartments() {
      this.loading = true;
      const departments = await getDepartments();
      this.departments = departments;
      this.loading = false;
    }
  },

  created() {
    this.getDepartments();
  }
};
</script>
